.searchbar-v1 {

	/*position: absolute;
	bottom: 12vh;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;

	@media(max-width: 992px) {
		bottom: 5vh;
	}*/


	.tabs {
		padding-left: 12px;

		.tab {
			padding: 6px 20px;
			opacity: 1;
			cursor: pointer;
			display: inline-block;
			border: 1px solid var(--color-grey-normal);

			@media(max-width:575px) {
				font-size: 14px;
				padding: 6px 12px;
			}

			&.active {
				background-color: var(--color-primary);
				color: #fff;
			}

		}

	}

	#search-obj-form {
		display: none;
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-flow: wrap;
		background-color: var(--color-grey-normal);
		/*background-color: #fff;
		background-color: var(--color-white);
		border: 10px solid #eaeaea;
		border: 10px solid var(--color-grey-light);
		border-radius: 8px;*/
		margin: 0px 0px;
		padding: 10px;

		.input-group {
			padding: 0;

			.form-btn {
				display: grid;
				padding: 0 10px;
			}
		}

		.form-btn {
			margin-left: 0px !important;
		}

		.form-obj-flex {
			width: 86%;
			padding: 10px;

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}

			.fa,
			.fa-sharp {
				position: absolute;
				left: 10px;
				top: 8px;
			}

			.form-control {
				background-color: #fff;
				text-align: left;
				position: relative;
				cursor: pointer;
				border: 0;
				min-height: 34px !important;
				font-size: 14px;
				padding: 0 12px;
				border-radius: 0;
				padding-left: 30px;
			}

			.form-flex-area {
				position: relative;
				background-color: var(--color-white);
			}

		}

		.form-flex {
			width: 43%;
			padding: 10px;
			/*border-right: 1px solid #f4f4f4;*/

			.form-flex-area {

				.fa-sharp {
					background-repeat: no-repeat;
					background-size: contain;
					height: 18px;
					width: 18px;
					display: inline-block;

					&:before {
						content: "";
					}

					&.fa-calendar {
						background-image: url(RESOURCE/img/icon-calender.svg);
					}

					&.fa-user {
						background-image: url(RESOURCE/img/icon-users.svg);
					}
				}
			}


			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}


			.fa,
			.fa-sharp {
				position: absolute;
				left: 10px;
				top: 8px;
			}

			.form-control {
				padding-left: 30px !important;
			}

			button {
				height: auto !important;
			}

			/*#datepicker-trigger-flex {

				height: auto !important;
				padding-right: 30px;
			}*/

			.form-control {
				background-color: #fff;
				text-align: left;
				position: relative;
				cursor: pointer;
				border: 0;
				min-height: 34px !important;
				font-size: 14px;
				padding: 0px 12px;
				border-radius: 0;

				&:focus {
					border-color: #86b7fe !important;
				}
			}

			.form-flex-area {
				position: relative;
			}
		}

		.form-btn {
			width: 14%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;

			@media (max-width:992px) {
				width: 100%;
				padding-bottom: 5px;
			}

			.btn {
				width: 101%;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 20px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}