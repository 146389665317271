.unit-properties-v1 {
	.d-title {
		/*font-size: var(--h5-font-size);
		font-weight: 400;
		margin-bottom: 1rem;
		background-color: var(--color-grey-light);
		padding-left: 5px;
		margin-left: -5px;*/
		padding-bottom: 10px;
		font-family: var(--font-family-main);
		color: var(--color-primary);
		font-weight: 400;
		font-size: 18px;
	}

	.tick-li {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		@media (max-width:992px) {
			font-size: var(--font-size-md);
		}


		li {
			width: 50%;
			padding-left: 30px;
			padding-right: 15px;
			min-height: 30px;
			position: relative;

			@media (max-width:480px) {
				width: 100%;
			}

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 6 Sharp";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-black);
			}
		}
	}
}