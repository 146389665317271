.unit-description-v1 {
	.description-block {
		margin-bottom: 40px;

		.d-title {
			padding-bottom: 10px;
			font-family: var(--font-family-main);
			color: var(--color-primary);
			font-weight: 400;
			font-size: 18px;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;


		}
	}
}