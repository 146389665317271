.unit-features-v1 {
	.feature-group {
		background-color: #F4F0ED;
		padding: 15px 20px;
		color: var(--color-grey-brown);

		.ul-nopints {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 10px;

			@media (max-width:992px) {
				font-size: var(--font-size-md);
			}


			li {
				width: 50%;
				padding-left: 30px;
				padding-right: 15px;
				min-height: 30px;
				margin-bottom: 10px;
				position: relative;
				display: none;

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6) {
					display: block;
				}

				@media (max-width:480px) {
					width: 100%;
				}

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 6 Sharp";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-black);
				}
			}
		}
	}
}