.index-slide-show-v1 {
	position: relative;
	/*height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);*/

	.owl-carousel {
		z-index: unset;
	}

	.banner-text {
		position: absolute;
		bottom: 25%;
		width: 100%;

		@media (max-width:992px) {

			bottom: 10px;
		}

		.container {
			position: relative;
		}
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		@media (max-height: 600px) {
			min-height: 280px;

		}

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;

			@media (max-height: 600px) {
				min-height: 280px;

			}
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			overflow: hidden;

			@media (max-height: 600px) {
				min-height: 280px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				object-position: top;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}


	.hero-text {
		/*text-align: center;*/


		.hero-headline {

			padding: 5px 0 0;
			display: inline-block;

			@media (max-width:767px) {
				top: 3px;
				position: relative;
			}
		}

		.text-background {
			display: inline;
			box-decoration-break: clone;
			background-color: transparent;
			padding: 0 10px;

		}

		/* Farbvariationen */
		.orange-bg {
			background-color: #ffac2a;

		}

		.gray-bg {
			background-color: #b8aba0;

		}

		.h1 {
			line-height: 1.1;
			font-family: var(--hero-header-font-family);
			font-size: 3rem;
			color: #fff;
			font-weight: var(--title-font-weight-main);
			margin-bottom: 0px;

			@media (min-width: 1600px) {
				font-size: 4rem;
			}

			@media (max-width: 992px) {
				font-size: 2.5rem;
			}

			@media (max-width: 767px) {
				font-size: 1.6rem;
				//line-height: 2.25rem;
			}

		}

		.hero-subtitle {
			line-height: 1.3;
			display: inline-block;
			padding: 5px 0;

			font-family: var(--hero-header-sub-title-font-family);
			font-size: 1.8rem;
			color: #fff;
			font-weight: 800;

			@media (min-width: 1600px) {
				font-size: 1.8rem;
			}

			@media (max-width: 767px) {
				font-size: 1.1rem;
			}
		}
	}

}