.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.cust-row {
		@media (min-width:576px) {
			margin: 0 -15px;
			flex-wrap: wrap;
		}

		>div {
			@media (min-width:576px) {
				padding: 0 15px;
			}
		}
	}



	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.check-list {
		column-count: 2;
		margin-top: 10px;
		margin-bottom: 0;

		@media(max-width:767px) {
			column-count: 1;
		}

		li {
			padding-left: 30px;
			position: relative;
			margin-bottom: 10px;

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				font-size: 16px;
				color: #000;
				list-style: none;
				font-weight: 300;
				font-family: "Font Awesome 6 Sharp";
				content: "";
			}
		}
	}

	/*.coastal-form {
		max-width: 400px;

		.form-control {
			border: 0;
			border-radius: 0;
		}

		.text-right {
			text-align: right;
		}
	}*/

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin: 0 auto;
				margin-bottom: 30px;
				background-image: url(RESOURCE/img/icon-star-solid.svg);
				background-repeat: no-repeat;
				background-size: contain;
				height: 70px;
				filter: brightness(0) saturate(100%) invert(87%) sepia(30%) saturate(5858%) hue-rotate(337deg) brightness(105%) contrast(102%);
				width: 70px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

}