.ueber-uns-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 280px;
		}


		&.ueber-uns {
			background-image: url(RESOURCE/img/inner-banner-ueber_uns.webp);
		}
	}

	form {
		.form-control {
			border: 0;
			border-radius: 0;
		}

		.form-btn {
			text-align: right;

			.btn {
				padding: 6px 60px;
				font-weight: 600;
			}
		}

		.terms-link {
			text-decoration: underline;
		}
	}



	.page-title {
		position: absolute;
		bottom: 25%;
		width: 100%;

		@media (max-width:992px) {
			bottom: 10px;
		}

		.pag-title {
			@media (max-width:400px) {
				display: flex;
				flex-direction: column;

				br {
					display: none;
				}
			}

			.text-background {
				display: inline;
				box-decoration-break: clone;
				background-color: transparent;
				padding: 0 10px;

			}

			/* Farbvariationen */
			.orange-bg {
				background-color: #ffac2a;

			}

			.gray-bg {
				background-color: #b8aba0;

			}

			.container {
				position: relative;
			}
		}

		.container {
			position: relative;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin: 0 auto;
				margin-bottom: 30px;
				background-image: url(RESOURCE/img/icon-star-solid.svg);
				background-repeat: no-repeat;
				background-size: contain;
				height: 70px;
				filter: brightness(0) saturate(100%) invert(87%) sepia(30%) saturate(5858%) hue-rotate(337deg) brightness(105%) contrast(102%);
				width: 70px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

}