.unit-sub-title-v1 {
	padding-bottom: 20px;

	@media(min-width:768px) {
		display: flex;
		justify-content: space-between;
	}

	h1 {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	.unit-subtitle {
		font-size: var(--font-size-md);
		padding-top: 5px;

		.fa,
		.fas,
		.far,
		.fa-sharp {
			width: 20px;
		}

		.type {
			color: var(--color-grey-brown);
		}

		.address {
			color: var(--color-grey-dark);
		}
	}

	.avg {

		@media(min-width:768px) {
			text-align: center;
		}

		@media(max-width:767px) {
			padding-top: 10px;
		}

		.avg-count {
			font-size: var(--font-size-md);
			white-space: nowrap;


		}
	}

	.avg-symbol {
		display: inline-block;
		background-color: var(--bg-color-feedback);
		color: var(--font-color-feedback);
		padding: 5px;
		border-radius: var(--border-radius);
		font-size: 18px;
		cursor: pointer;
	}

}