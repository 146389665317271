.unit-room-details-v1 {

	display: flex;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.rooms {

		width: 50%;
		max-width: 49%;
		border: 1px solid var(--color-grey-dark);
		padding: 10px;
		margin-bottom: 15px;

		@media (max-width:767px) {
			width: 100%;
			max-width: 100%;
		}

		.room {
			/*font-weight: 600;*/
			padding-bottom: 10px;
			font-family: var(--font-family-main);
			color: var(--color-primary);
			font-weight: 400;
			font-size: 18px;

			@media (max-width:992px) {
				font-size: var(--font-size-md);
			}

		}

		.room-details {
			ul {

				li {
					padding-left: 0px;
					position: relative;

					@media (max-width:992px) {
						font-size: var(--font-size-md);
					}
				}
			}
		}

	}

	.d-title {
		padding-bottom: 10px;
		font-family: var(--font-family-main);
		color: var(--color-primary);
		font-weight: 400;
		font-size: 18px;
	}
}